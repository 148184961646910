.notification--container {
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;

        color: #000000;
    }
    &__items {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        gap: 8px;

        .item {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            padding: 10px;
            gap: 12px;
            height: 94px;
            background: #ffffff;
            border: 1px solid #f4f4f4;
            border-radius: 8px;
            flex: none;
            order: 0;
            flex-grow: 0;

            .date {
                width: 163px;
                height: 14px;
                display: flex;
                gap: 10px;
                padding: 0;
                margin: 0;
                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 10px;
                    line-height: 140%;
                    color: #323232;
                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
            }

            .content {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    background: rgba(#ffa524, 0.1);
                    display: grid;
                    place-items: center;
                }

                p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #000000;

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }

        .loader {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 180px;
        }
    }
}
