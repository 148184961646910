label {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    color: #323232;
}
input {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
}
.send-gift--container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    font-family: "circular_std_book";
    height: calc(100vh - 98px);
    &__form {
        height: calc(100% - 54px);
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            color: #000000;
            margin-bottom: 12px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #000000;

            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    .batch--contact {
        margin-top: 10px;
        height: 380px;
        margin-bottom: 5rem;
        overflow-y: scroll;
        label {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #000000;
        }
        .select--phonebook {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            cursor: pointer;
            color: #3b82f6;
        }
    }

    &__button {
        position: fixed;
        bottom: 10px;
        left: 16px;
        right: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            width: 343px;
        }
    }
    .batch--info {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 8px;
        height: 73px;
        padding: 0;

        background: #fef3c7;
        border: 1px solid rgba(146, 64, 14, 0.1);
        border-radius: 4px;
        li {
            display: flex;
            align-items: center;
            padding: 10px;
            margin: 0;
            gap: 5px;
            // width: 299px;
            span {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                /* or 170% */

                color: #92400e;
            }
        }
    }
}

.phonebook--container {
    position: fixed;
    width: 100vw;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: rgba(53, 53, 53, 0.3);
    font-family: "circular_std_book";

    &__list {
        position: fixed;
        width: 100vw;
        left: 0;
        right: 0;
        bottom: 0;
        height: 411px;
        background: #fbfbfb;
        border-radius: 20px 20px 0px 0px;
        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            padding: 20px;
            color: #000000;
        }
        .lists {
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            .item {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 16px;
                gap: 8px;
                height: 77px;

                background: #ffffff;
                border: 1px solid rgba(216, 216, 216, 0.7);
                border-radius: 8px;
                flex: none;
                order: 0;
                flex-grow: 0;

                .icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    background: rgba(235, 41, 49, 0.12);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: #bc0c13;
                    display: grid;
                    place-items: center;
                }

                .details {
                    .name {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 24px;
                        padding: 0;
                        margin: 0;
                        color: #000000;
                    }
                    .total {
                        font-style: normal;
                        font-weight: 450;
                        font-size: 12px;
                        line-height: 20px;
                        color: #969696;
                        padding: 0;
                        margin: 0;
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                }
            }
        }
    }
}

.message-type--container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    font-family: "circular_std_book";
    &__type {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            color: #969696;
        }

        .message--types {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 40px;
            &__item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                height: 40px;

                background: #ffffff;
                border: 1px solid rgba(216, 216, 216, 0.7);
                border-radius: 8px;

                div {
                    display: flex;
                    gap: 20px;

                    .icon {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background: #f4f4f4;
                        display: grid;
                        place-items: center;
                        img {
                            width: 24px;
                            width: 24px;
                        }
                    }
                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        /* identical to box height, or 170% */

                        color: #000000;
                    }
                }
            }
        }
    }
}

.record-message--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "circular_std_book";

    .record--section {
        margin-top: 130px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .microphone--icon {
            width: 100px;
            height: 100px;
            border-radius: 100%;
            background: #f4f4f4;
            display: grid;
            place-items: center;

            img {
                width: 60px;
                height: 60px;
            }
        }

        .rec {
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            color: #323232;
            flex: none;
            position: relative;
            &::before {
                content: "";
                width: 10px;
                height: 10px;
                background: #eb2931;
                position: absolute;
                flex: none;
                order: 0;
                flex-grow: 0;
                top: 6px;
                right: 30px;
                border-radius: 100%;
            }
        }

        .recording--time {
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            text-align: center;
            color: #969696;
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }

    &__button {
        position: fixed;
        left: 16px;
        right: 16px;
        bottom: 20px;

        gap: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        button {
            width: 167px;
        }

        .start--button {
            button {
                width: 343px;
            }
        }
    }
}

.gift-type--container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    font-family: "circular_std_book";
    &__type {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            color: #969696;
        }

        .gift--types {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 40px;
            &__item {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                height: 40px;

                background: #ffffff;
                border: 1px solid rgba(216, 216, 216, 0.7);
                border-radius: 8px;

                div {
                    display: flex;
                    gap: 20px;

                    .first-icon {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background: #f0fdf4;
                        display: grid;
                        place-items: center;
                        cursor: pointer;
                        img {
                            width: 24px;
                            width: 24px;
                        }
                    }
                    .last-icon {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background: #eff6ff;
                        display: grid;
                        place-items: center;
                        cursor: pointer;
                        img {
                            width: 24px;
                            width: 24px;
                        }
                    }
                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        /* identical to box height, or 170% */

                        color: #000000;
                    }
                }
            }
        }
    }
}

.upload-message--section {
    font-family: "circular_std_book";
    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            width: 343px;
        }
    }
}

.tts--section {
    height: calc(100% - 98px);
    &__container {
        height: calc(100vh - 34px);
        overflow-y: scroll;
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            color: #969696;
        }

        p:last-child {
            text-align: right;
        }

        .textarea {
            margin-top: 24px;
            width: 90%;
            height: 198px;
            background: #ffffff;
            border: 1px solid rgba(216, 216, 216, 0.7);
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #323232;
            font-family: "circular_std_book";

            &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                font-family: "circular_std_book";
                color: #d8d8d8;
            }
        }
    }
    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            width: 343px;
        }
    }
}

.gift--container {
    &__header {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            color: #000000;
            flex: none;
            order: 0;
            flex-grow: 0;
        }
        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            color: #969696;
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }

    &__items {
        margin-top: 44px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 10px;

        .item {
            width: 100px;
            height: 60px;
            background: #ffffff;
            border: 1px solid rgba(216, 216, 216, 0.7);
            border-radius: 8px;
            display: grid;
            place-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 31px;
            text-align: center;
            cursor: pointer;
            color: #000000;
        }

        .select-item {
            cursor: pointer;
            width: 109px;
            height: 60px;
            background: #ffffff;
            border: 1px solid #bc0c13;
            border-radius: 8px;
            display: grid;
            place-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 31px;
            text-align: center;

            color: #000000;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            width: 343px;
        }
    }
}

.summary--container {
    p {
        padding: 0;
        margin: 0;
    }
    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px;
        gap: 10px;
        margin-top: 24px;
        background: #ffffff;
        border: 1px solid rgba(216, 216, 216, 0.7);
        border-radius: 8px;
        div {
            padding: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .summary {
                margin-bottom: 18px;
                .title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #969696;
                }
                .info {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 24px;
                    color: #000000;
                    // width: 220px;
                    text-transform: capitalize;
                    padding-top: 4px;
                }

                .multiple {
                    color: red;
                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                        color: #000000;
                        width: 100px;
                        padding-top: 4px;
                    }

                    span:last-child {
                        color: #969696;
                        font-size: 13px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .available-unit {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #d97706;
        text-align: right;
        position: relative;
        margin-top: 8px;
        m &::before {
            content: url("../../assets/icons/info.svg");
            width: 20px;
            height: 20px;
            position: absolute;
            top: 1px;
            margin-left: -27px;
        }
    }

    .info--section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 8px;
        gap: 8px;
        width: 343px;
        height: 53px;
        background: #fef3c7;
        border: 1px solid rgba(146, 64, 14, 0.1);
        border-radius: 4px;

        p {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            width: 299px;
            height: 41px;
            color: #92400e;
        }
    }

    &__schedule {
        .schedule--content {
            label {
                padding: 0;
                margin: 0;
            }
            .requirement--list {
                margin-top: 0px;
            }
        }
    }

    &__button {
        width: 100%;
        button {
            width: 100%;
        }
    }
}

.success--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__icon {
        width: 150px;
        height: 150px;
        background: #f4f4f4;
        border-radius: 100%;
        display: grid;
        place-items: center;
        margin-top: 62px;
    }

    &__content {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */

            text-align: center;

            color: #000000;
        }
        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 20px;
            text-align: center;

            /* gray/2 */

            color: #969696;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;
    }
}
