@import "../../../styles/base.scss";

.update-profile--container {
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        color: #000000;
        margin-bottom: 47px;
    }
    form {
        input {
            width: 100%;
        }
        .form__container--row {
            display: flex;
            gap: 8px;

            div {
                // width: 40%;
            }
        }

        .form__container--custom {
            // border: 1px solid $border-color;
            border-radius: 6px;
            position: relative;
            display: flex;
            gap: 4px;
        }

        .button {
            position: fixed;
            bottom: 20px;
            right: 16px;
            left: 16px;
            margin-top: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            button {
                width: 343px;
            }
        }
    }
}

.success--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__icon {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        display: grid;
        place-items: center;
        margin-top: 62px;
    }

    &__content {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */

            text-align: center;

            color: #000000;
        }
        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            width: 290.87px;
            height: 36px;
            color: #969696;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;
    }
}
