.tag-input--container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px;
    gap: 10px;
    height: 145px;
    margin-top: 5px;

    background: #fbfbfb;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    overflow-y: scroll;

    &__items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        .tag--item {
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 8px;
            gap: 4px;

            height: 36px;
            background: #eff6ff;
            border: 1px solid #126abd;
            border-radius: 8px;
            width: auto;

            flex: none;
            order: 0;
            flex-grow: 0;

            p {
                width: auto;
                height: 20px;

                font-style: normal;
                font-weight: 450;
                font-size: 14px;
                line-height: 20px;
                color: #2563eb;

                flex: none;
                order: 0;
                flex-grow: 0;

                margin-right: 5px;
            }

            span {
                cursor: pointer;
            }
        }

        input {
            width: 285px;
            height: 36px;
            border: none;
        }
    }
}
