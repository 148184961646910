.wallet--container {
    display: flex;
    flex-direction: column;

    &__day {
        margin: 20px;
        height: 185px;
        background: url("../../assets/images/day.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        position: relative;
        // overflow: hidden;
        margin-top: 9px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            text-align: center;

            /* gray/3 */

            color: #d8d8d8;

            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
        }

        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 55px;
            text-align: center;
            color: #f4f4f4;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0px;
        }
    }
    &__night {
        margin: 20px;
        height: 185px;
        background: url("../../assets/images/night.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 10px;
        position: relative;
        // overflow: hidden;
        margin-top: 9px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            /* identical to box height, or 143% */

            text-align: center;

            /* gray/3 */

            color: #d8d8d8;

            /* Inside auto layout */

            flex: none;
            order: 0;
            flex-grow: 0;
        }

        h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 55px;
            text-align: center;
            color: #f4f4f4;
            flex: none;
            order: 0;
            flex-grow: 0;
            margin: 0px;
        }
    }

    &__button {
        margin: 0px 20px;
        margin-top: 24px;
        position: relative;
        &::before {
            content: url("../../assets/icons/add.svg");
            position: absolute;
            width: 40px;
            height: 40px;
            z-index: 1;
            left: 7.3rem;
            top: 15px;
        }
    }

    &__transaction {
        margin-top: 24px;

        .title {
            padding: 0px 20px;
            height: 31px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 31px;

            color: #000000;
        }

        .transaction--histories {
            overflow: scroll;
            height: 480px;
            background: #ffffff;
            // width: 100vw;

            .history {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px 20px;
                gap: 10px;
                .icon {
                    display: grid;
                    place-items: center;
                    width: 42px;
                    height: 42px;
                    background: #fbfbfb;
                    border-radius: 100%;
                }

                .content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    h3 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;

                        color: #000000;

                        width: 280px;
                        overflow-x: scroll;
                    }
                    p {
                        display: flex;
                        gap: 13px;
                        padding: 0;
                        margin: 0;
                        span {
                            font-style: normal;
                            font-weight: 450;
                            font-size: 12px;
                            line-height: 20px;
                            color: #969696;
                        }
                    }

                    .amount {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 31px;
                        text-align: center;
                    }
                }
            }

            .loader {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                height: 180px;
            }
        }
    }
}

@media screen and (min-width: 360px) and (max-width: 400px) {
    .wallet--container {
        &__button {
            &::before {
                left: 109px;
            }
        }

        &__transaction {
            .transaction--histories {
                .history {
                    .content {
                        h3 {
                            font-size: 12px;
                            width: 240px;
                        }
                        p {
                            span {
                                font-size: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}
