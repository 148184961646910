.message--layout {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    &__header {
        width: 343px;
        display: flex;
        gap: 80px;
        align-items: center;
        margin: 10px 0px;
        .go--back {
            cursor: pointer;
        }
        .progress {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            gap: 10px;
            .step--one {
                width: 60px;
                height: 4px;
                background: #d8d8d8;
                border-radius: 20px;
            }
        }
    }

    main {
        width: 343px;
    }

    .report--container {
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0px;
        top: 0px;

        display: flex;
        justify-content: center;
        background: rgba(53, 53, 53, 0.3);
        .wrapper {
            padding: 20px;
            position: fixed;
            bottom: 1px;
            width: 343px;
            height: 411px;
            background: #fbfbfb;
            border-radius: 20px 20px 0px 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 40px;
            .header {
                display: flex;
                align-items: center;
                gap: 10px;

                p {
                    font-family: "circular_std_book";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 36px;
                    /* identical to box height, or 180% */

                    text-align: center;

                    color: #000000;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }

            .form {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 90%;
                label {
                    font-family: "circular_std_book";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    /* identical to box height, or 170% */

                    text-align: center;
                    letter-spacing: -0.01em;

                    color: #000000;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                textarea {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 16px;
                    gap: 8px;

                    width: 90%;
                    height: 138px;

                    background: #ffffff;
                    border: 1px solid rgba(216, 216, 216, 0.7);
                    border-radius: 8px;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }
    }
}
