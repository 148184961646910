.top-up--container {
    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        button {
            width: 343px;
        }
    }
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        color: #000000;
    }

    p {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 31px;
        color: #969696;
    }

    .top-up {
        margin-top: 35px;
        .form--group {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            label {
                font-style: normal;
                font-weight: 450;
                font-size: 18px;
                line-height: 31px;
                text-align: center;

                color: #000000;
            }

            input {
                width: 164px;
                height: 55px;
                left: 200px;
                top: 219px;
                font-style: normal;
                font-weight: 700;
                font-size: 42px;
                line-height: 55px;
                text-align: center;
                border: none;
                color: #1e293b;
            }

            p {
                font-style: normal;
                font-weight: 450;
                font-size: 16px;
                line-height: 31px;
                text-align: center;
                color: #969696;
            }
        }

        .form--group:first-child {
            padding-bottom: 16px;
            border-bottom: 1px solid #d8d8d8;
        }
    }

    .info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        div {
            width: 221px;
            padding: 5px 0px;
            background: #ffffff;
            border: 1px solid #d8d8d8;
            border-radius: 20px;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 31px;
            text-align: center;

            color: #000000;
        }
    }
}

.payment-method--container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    font-family: "circular_std_book";
    &__type {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            color: #969696;
        }

        .method--types {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 40px;
            &__item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                height: 40px;

                background: #ffffff;
                border: 1px solid rgba(216, 216, 216, 0.7);
                border-radius: 8px;

                div {
                    display: flex;
                    gap: 20px;

                    .first-icon {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background: #feefd7;
                        display: grid;
                        place-items: center;
                        cursor: pointer;
                        img {
                            width: 24px;
                            width: 24px;
                        }
                    }
                    .last-icon {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background: #e7ebee66;
                        display: grid;
                        place-items: center;
                        cursor: pointer;
                        img {
                            width: 24px;
                            width: 24px;
                        }
                    }
                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        /* identical to box height, or 170% */

                        color: #000000;
                    }
                }
            }
        }
    }
}

.success--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__icon {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        display: grid;
        place-items: center;
        margin-top: 62px;
    }

    &__content {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */

            text-align: center;

            color: #000000;
        }
        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            width: 290.87px;
            height: 36px;
            color: #969696;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;
    }
}

.bank--container {
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;

        color: #000000;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }
    p {
        font-style: normal;
        font-weight: 450;
        font-size: 16px;
        line-height: 31px;

        color: #969696;

        flex: none;
        order: 1;
        flex-grow: 0;
    }

    &__content {
        margin-top: 40px;
        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;

            color: #000000;
        }

        .account--info {
            box-sizing: border-box;

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            // width: 343px;
            height: 152px;

            background: #ffffff;
            border: 1px solid rgba(216, 216, 216, 0.7);
            border-radius: 8px;

            .sub-title {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #969696;
                }
            }
            .content {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                p {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #000000;

                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    gap: 8px;
                }
            }
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        button {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            width: 343px;
            color: #ffffff;
        }
    }
}
