.form--fields {
    height: 350px;
    overflow-y: scroll;
}
.button {
    position: fixed;
    bottom: 20px;
    left: 16px;
    right: 16px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    button {
        width: 343px;
    }
}

.info {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    color: #d97706;

    flex: none;
    order: 1;
    flex-grow: 0;

    position: relative;
    margin-left: 30px;

    &::before {
        position: absolute;
        content: url("../../../assets/icons/info.svg");
        left: -25px;
    }
}

.sample {
    box-sizing: border-box;

    margin: 29px auto;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 106px;
    height: 32px;
    border-radius: 8px;

    border: 1px solid #d8d8d8;

    p {
        width: 86px;
        height: 21px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 170% */

        text-align: center;

        /* gray/1 */

        color: #323232;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }
}

.success--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__icon {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        display: grid;
        place-items: center;
        margin-top: 62px;
    }

    &__content {
        h3 {
            width: 290.87px;
            height: 36px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;

            text-align: center;

            color: #000000;

            /* Inside auto layout */

            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
        }
        p {
            width: 290.87px;
            height: 40px;
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            text-align: center;

            /* gray/2 */

            color: #969696;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;
    }
}

.sample--modal {
    width: 305px;
    height: 383px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 250px;
        height: 244px;
    }

    button {
        margin-top: 13px;
        width: 108px;
        height: 42px;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
    }
}
