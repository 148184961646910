.bank-details--container {
    input {
        background: #ffffff;
        /* gray/3 */

        border: 1px solid #d8d8d8;
        border-radius: 4px;
    }

    label {
        font-family: "circular_std_book";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 170% */

        letter-spacing: -0.01em;

        color: #000000;
    }
    h3 {
        font-family: "circular_std_black";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;
        /* identical to box height, or 180% */

        letter-spacing: -0.02em;

        color: #000000;
    }
    &__form {
        padding-top: 2rem;
        .bank-list {
            .bank-list-header {
                box-sizing: border-box;

                cursor: pointer;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                gap: 10px;

                height: 48px;

                background: #ffffff;
                /* gray/3 */

                border: 1px solid #d8d8d8;
                border-radius: 4px;

                /* Inside auto layout */

                flex: none;
                order: 1;
                align-self: stretch;
                flex-grow: 0;
            }
            .search {
                box-sizing: border-box;

                /* Auto layout */

                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 8px 16px;
                gap: 8px;

                height: 45px;
                background: #ffffff;
                border: 1px solid #d8d8d8;
                border-radius: 4px;
                border-bottom: none;

                input {
                    background: #ffffff;
                    height: 43px;
                    width: 100%;
                    border: none;
                    border-left: 1px solid #d8d8d8;
                    border-radius: 0px;
                }
            }
            .list {
                display: flex;
                flex-direction: column;
                height: 280px;
                background: #ffffff;
                /* gray/3 */
                border-top: none;
                border: 1px solid #d8d8d8;
                border-radius: 4px;
                overflow: auto;

                p {
                    padding: 0px 20px;
                    cursor: pointer;
                }
            }
        }

        .loader {
            display: flex;
            align-items: center;
            gap: 5.6px;
        }
    }

    &__button {
        position: fixed;
        bottom: 10px;
        left: 16px;
        right: 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            width: 343px;
        }
    }
}
.send-gift--container {
    &__form {
        padding-top: 2rem;
        label {
            font-family: "circular_std_black";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 170% */

            letter-spacing: -0.01em;

            color: #000000;
        }

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            color: #000000;
            margin-bottom: 12px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #000000;

            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }

    &__button {
        position: fixed;
        bottom: 10px;
        left: 16px;
        right: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .schedule {
            width: 343px;
            label {
                font-family: "circular_std_black";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 170% */

                letter-spacing: -0.01em;

                color: #969696;
            }
        }
        button {
            width: 343px;
        }
    }
}
