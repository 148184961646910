.header--wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    background-color: #ffffff;
    .header--container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 45%;
        width: 1440px;
        &__logo {
            cursor: pointer;
            p {
                width: 90px;
                font-family: "Comfortaa";
                font-style: normal;
                font-weight: 400;
                font-size: 44.2708px;
                line-height: 58px;
                /* or 130% */

                /* neutral / black */
                color: #eb2931;
                span {
                    width: 90px;
                    height: 37px;
                    font-family: "Comfortaa";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 44.2708px;
                    line-height: 58px;
                    /* or 130% */

                    color: #000000;
                }
            }

            .burger--icon {
                display: none;
            }
        }

        &__navbar {
            width: 610px;
            height: 50px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0px;
            gap: 80px;

            .nav--links {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0px;
                gap: 32px;

                width: 310px;
                height: 31px;

                a {
                    font-family: "circular_std_medium";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 31px;
                    /* identical to box height, or 170% */

                    color: #000000;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }

            .auth--links {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0px;
                gap: 24px;

                width: 220px;
                height: 50px;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;

                a {
                    font-family: "circular_std_medium";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 31px;
                    /* identical to box height, or 170% */

                    color: #000000;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }

                button {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 16px 24px;
                    gap: 10px;

                    width: 145px;
                    height: 50px;

                    /* primary / active */

                    background: #eb2931;
                    border-radius: 6px;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;

                    font-family: "circular_std_medium";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 31px;
                    /* identical to box height, or 170% */

                    color: #ffffff;
                }
            }
        }
    }
}

@media screen and (min-width: 1290px) and (max-width: 1390px) {
    .header--wrapper {
        width: 100%;
        align-items: center;
        .header--container {
            gap: 40%;
        }
    }
}
@media screen and (min-width: 320px) and (max-width: 600px) {
    .header--wrapper {
        padding-top: 10px;
        padding-bottom: 20px;
        flex-direction: row;

        .header--container {
            flex-direction: column;

            width: 343px;
            gap: 10px;
            &__logo {
                p {
                    width: 90px;
                    height: 37px;
                    font-family: "Comfortaa";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 27.7631px;
                    line-height: 36px;
                    /* or 130% */

                    /* neutral / black */
                    color: #eb2931;
                    span {
                        width: 90px;
                        height: 37px;
                        font-family: "Comfortaa";
                        font-style: normal;
                        font-weight: 400;
                        font-size: 27.7631px;
                        line-height: 36px;
                        /* or 130% */

                        color: #000000;
                    }
                }

                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                .burger--icon {
                    display: block;
                }
            }

            &__navbar {
                width: 100%;
                display: none;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                gap: 36px;
                height: 234px !important;

                .nav--links {
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    padding: 0px;
                    gap: 5px;

                    width: 320px;
                    height: 121px;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    align-self: stretch;
                    flex-grow: 0;

                    a {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 31px;
                        /* identical to box height, or 170% */

                        color: #000000;
                    }
                }

                .auth--links {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    gap: 16px;

                    width: 100%;
                    height: 97px;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0;

                    a {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 31px;
                        /* identical to box height, or 170% */

                        color: #000000;
                    }

                    button {
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 16px 24px;
                        gap: 10px;

                        width: 100%;
                        height: 50px;

                        /* primary / active */

                        background: #eb2931;
                        border-radius: 6px;

                        /* Inside auto layout */

                        flex: none;
                        order: 1;
                        align-self: stretch;
                        flex-grow: 0;
                    }
                }
            }
        }
    }
}
