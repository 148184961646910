.verify--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 97px;

    .verify--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__image {
            border-radius: 100%;
            display: grid;
            place-items: center;

            img {
                width: 70px;
                height: 70px;
                padding: 20px;
                background: #f6f7f8;
                border-radius: 100%;
            }
        }
        &__title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;

            text-align: center;

            color: #000000;
        }

        &__text {
            width: 290.87px;
            height: 36px;

            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 18px;
            text-align: center;

            padding-top: 9px;

            color: #969696;
        }
    }

    .button {
        position: fixed;
        bottom: 20px;
        width: 100%;
        left: 16px;
        right: 16px;

        button {
            width: 90vw;
        }
    }
}
