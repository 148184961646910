.policy--container {
    padding: 20px;

    .go--back {
        cursor: pointer;
    }
    h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 36px;
        color: #000000;
    }

    &__content {
        p {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            color: #323232;

            flex: none;
            order: 0;
            flex-grow: 0;
        }

        .title {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;

            color: #000000;

            flex: none;
            order: 0;
            flex-grow: 0;
        }
        ul {
            margin: 0px;
            padding: 0px 20px;
        }
        .text,
        li {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            color: #323232;

            flex: none;
            order: 0;
            flex-grow: 0;
        }
    }
}

@media screen and (min-width: 1024px) {
    .policy--container {
        padding: 20px;
        position: absolute;
        left: 5%;
    }
}
