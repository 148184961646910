.settings--container {
    height: 760px;
    overflow-y: scroll;
    &__profile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;

        p {
            margin: 0;
            padding: 0;
        }
        .icon {
            width: 60px;
            height: 60px;
            border-radius: 100%;
            background: rgba(235, 41, 49, 0.12);
            display: grid;
            place-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 31px;
            color: #bc0c13;
            margin-bottom: 8px;
        }

        .name {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #000000;
            flex: none;
            order: 0;
            flex-grow: 0;
        }

        .phone {
            font-style: normal;
            font-weight: 450;
            font-size: 12px;
            line-height: 20px;
            color: #969696;
            flex: none;
            order: 1;
            flex-grow: 0;
        }

        button {
            margin: 15px 0px;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            // padding: 16px 24px;
            gap: 8px;
            width: 90%;

            background: #eb2931;
            border-radius: 4px;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            /* identical to box height, or 170% */

            color: #ffffff;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }

    &__security,
    &__other,
    &__support {
        display: flex;
        flex-direction: column;
        padding: 15px 20px;

        .title {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            text-transform: uppercase;
            color: #969696;
            padding: 0;
            margin: 0;
            flex: none;
            order: 0;
            flex-grow: 0;
        }
        .action {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            height: 40px;
            margin-top: 5px;
            background: #ffffff;
            border: 1px solid rgba(216, 216, 216, 0.7);
            border-radius: 8px;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;

                .icon {
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    background: #f4f4f4;
                    display: grid;
                    place-items: center;
                    img {
                        width: 12px;
                        width: 12px;
                    }
                }
                p {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #000000;
                }
            }
        }
    }

    &__other {
        .action {
            div {
                .icon {
                    background: #fde5e6;
                }
                p {
                    color: #eb2931;
                }
            }
        }
    }
}
