@import "../Login/index.module.scss";
@import "../../styles/base.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .reset--password {
    padding: 20px 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #000000;
      text-align: left;
    }

    p {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      width: 343px;
      color: #969696;
      text-align: left;
    }

    form {
      position: relative;
      width: 375px;
      .button {
        font-family: "circular_std_book";
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        position: fixed;
        left: 16px;
        right: 16px;
        bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
          width: 375px;
        }
      }

      label {
        font-family: "circular_std_book";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 31px;

        color: #000000;
      }
    }
  }

  .password__hint {
    font-size: 14px !important;
  }
}

.reset--success {
  width: 610px;
  height: 461px;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px 40px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 115px;
    height: 115px;
  }

  form {
    // width: 530px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      width: 530px;
      color: #000000;
    }
    p {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      color: #969696;
    }

    button {
      margin-top: 60px;
      font-family: "circular_std_book";
      width: 530px;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 31px;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 600px) {
  .container {
    .reset--password {
      width: 100%;
      border-radius: 10px;
      padding: 20px;
      align-items: flex-start;

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        width: 290.87px;
      }

      p {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 24px;
      }

      form {
        margin-top: 0px;
        width: 100%;
        label {
          font-family: "circular_std_book";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
        }

        button {
          font-size: 14px;
          line-height: 13px;
        }
        span {
          margin-top: -5px;
        }
      }
    }
  }

  .reset--success {
    margin-top: 3rem;
    width: 334.77px;
    height: 372.87px;
    padding: 10px 20px;
    border-radius: 10px;

    img {
      width: 63.11px;
      height: 63.11px;
    }

    form {
      width: 100%;

      h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        width: 290.87px;
      }

      p {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 24px;
      }

      button {
        font-size: 14px;
        line-height: 13px;
        font-weight: 600;
        // width: 290.87px;
      }
    }
  }
}

.verify--container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 97px;

  .verify--content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__image {
      border-radius: 100%;
      display: grid;
      place-items: center;

      img {
        width: 70px;
        height: 70px;
        padding: 20px;
        background: #f6f7f8;
        border-radius: 100%;
      }
    }
    &__title {
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;

      text-align: center;

      color: #000000;
    }

    &__text {
      height: 36px;

      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      padding-top: 9px;

      color: #969696;
    }
  }

  .button {
    position: fixed;
    bottom: 20px;
    width: 100%;
    left: 16px;
    right: 16px;

    button {
      width: 90vw;
    }
  }
}
