.charity--container {
    &__type {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            color: #969696;
        }
        .search {
            margin-top: 32px;
            box-sizing: border-box;

            /* Auto layout */

            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 8px 16px;
            gap: 8px;

            height: 45px;
            background: #f4f4f4;
            /* gray/3 */

            border: 1px solid #d8d8d8;
            border-radius: 4px;

            input {
                background: #f4f4f4;
                height: 43px;
                width: 100%;
                border: none;
                border-left: 1px solid #d8d8d8;
                border-radius: 0px;
            }
        }
        .charity--list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            margin-top: 20px;
            overflow-x: scroll;
            &__item {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 12px 16px;
                justify-content: space-between;
                gap: 8px;
                cursor: pointer;
                background: #ffffff;
                border: 1px solid rgba(216, 216, 216, 0.7);
                border-radius: 4px;
                .details {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                    .icon {
                        display: grid;
                        place-items: center;
                        width: 36px;
                        height: 36px;
                        background: #3b82f6;
                        border-radius: 100%;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .name {
                        font-family: "circular_std_book";
                        font-style: normal;
                        font-weight: 450;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: -0.01em;

                        color: #000000;
                    }
                }

                .selected {
                    display: grid;
                    place-items: center;
                    width: 20px;
                    height: 20px;

                    background: #ffffff;
                    /* gray/3 */
                    border-radius: 100%;
                    border: 1.5px solid #eb2931;
                    .active {
                        width: 14px;
                        height: 14px;
                        border-radius: 100%;

                        background: #eb2931;
                    }
                }
                .radio {
                    box-sizing: border-box;

                    width: 20px;
                    height: 20px;

                    background: #ffffff;
                    /* gray/3 */
                    border-radius: 100%;
                    border: 1.5px solid #d8d8d8;
                }
            }
        }

        .loader {
            display: flex;
            align-items: center;
            gap: 5.6px;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            width: 343px;
        }
    }
}

.charity-details--container {
    overflow-y: hidden;
    h3 {
        font-family: "circular_std_black";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: -0.01em;

        color: #000000;
    }
    .location {
        display: flex;
        flex-direction: row;
        gap: 8px;
        p {
            font-family: "circular_std_book";
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 18px;
            /* identical to box height */

            text-align: center;
            letter-spacing: -0.01em;

            color: #969696;

            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }

    &__images {
        margin-top: 23px;
        margin-bottom: 38px;
        display: flex;
        flex-direction: row;
        gap: 8px;
        overflow-x: scroll;
        overflow-y: hidden;
        .image {
            width: 140px;
            height: 140px;
            border-radius: 4px;

            flex: none;
            order: 0;
            flex-grow: 0;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 1/3;
            }
        }
    }

    &__content {
        .heading {
            font-family: "circular_std_book";
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.01em;
            color: #000000;
        }

        .text-content {
            font-family: "circular_std_book";
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.01em;
            color: #323232;
        }
    }

    &__button {
        position: fixed;
        bottom: 10px;
        left: 16px;
        right: 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            width: 343px;
        }
    }
}

.gift--container {
    &__header {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            color: #000000;
            flex: none;
            order: 0;
            flex-grow: 0;
        }
        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            color: #969696;
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }

    &__amount {
        margin-top: 28px;
        margin-bottom: 62px;
        label {
            font-family: "circular_std_black";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: -0.01em;

            color: #000000;
        }

        .input {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 48px;

            background: #ffffff;
            /* gray/3 */

            border: 1px solid #d8d8d8;
            border-radius: 4px;

            p {
                font-style: normal;
                font-weight: 450;
                font-size: 14px;
                line-height: 20px;
                border: 1px solid #d8d8d8;
                letter-spacing: -0.01em;

                height: 48px;

                width: 80px;
                color: #323232;
                display: grid;
                place-items: center;
            }

            input {
                height: 48px;
                outline: none;
                width: 100%;
                background: #ffffff;
                font-style: normal;
                font-weight: 450;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.01em;
                border-left: 1px solid #d8d8d8;
                border: none;
                border-radius: 0px;
            }
        }
    }
    .options {
        font-family: "circular_std_black";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 170% */

        letter-spacing: -0.01em;

        color: #000000;
    }
    &__items {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 10px;

        .item {
            width: 100px;
            height: 60px;
            background: #ffffff;
            border: 1px solid rgba(216, 216, 216, 0.7);
            border-radius: 8px;
            display: grid;
            place-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 31px;
            text-align: center;
            cursor: pointer;
            color: #000000;
        }

        .select-item {
            width: 109px;
            height: 60px;
            background: #ffffff;
            border: 1px solid #bc0c13;
            border-radius: 8px;
            display: grid;
            place-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 31px;
            text-align: center;

            color: #000000;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            width: 343px;
        }
    }
}

.sender--container {
    &__header {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            color: #000000;
            flex: none;
            order: 0;
            flex-grow: 0;
        }
        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            color: #969696;
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }

    &__infos {
        .input-group {
            width: 343px;
            border: 1px solid #d8d8d8;
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            background-color: #ffffff;

            input {
                width: 100%;
                background: transparent;
                border: none;
                margin: 0;
            }
            img {
                margin-right: 10px;
                cursor: pointer;
            }
        }

        .textarea {
            width: 90%;
            height: 178px;
            border: 1px solid rgba(216, 216, 216, 0.7);
            border-radius: 8px;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #323232;
            font-family: "circular_std_book";

            &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                font-family: "circular_std_book";
                color: #d8d8d8;
            }
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .anonymous {
            width: 343px;
            display: flex;
            align-items: center;

            input {
                transform: scale(0.8);
            }
        }
        button {
            width: 343px;
        }
    }
}

.summary--container {
    p {
        padding: 0;
        margin: 0;
    }
    &__content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 10px;
        gap: 10px;
        margin-top: 24px;
        background: #ffffff;
        border: 1px solid rgba(216, 216, 216, 0.7);
        border-radius: 8px;
        div {
            padding: 5px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .summary {
                margin-bottom: 18px;
                .title {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #969696;
                }
                .info {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 24px;
                    color: #000000;
                    // width: 220px;
                    text-transform: capitalize;
                    padding-top: 4px;
                }

                .multiple {
                    color: red;
                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 24px;
                        color: #000000;
                        width: 100px;
                        padding-top: 4px;
                    }

                    span:last-child {
                        color: #969696;
                        font-size: 13px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
    .available-unit {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #d97706;
        text-align: right;
        position: relative;
        margin-top: 8px;
        m &::before {
            content: url("../../assets/icons/info.svg");
            width: 20px;
            height: 20px;
            position: absolute;
            top: 1px;
            margin-left: -27px;
        }
    }

    .info--section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 8px;
        gap: 8px;
        width: 343px;
        height: 53px;
        background: #fef3c7;
        border: 1px solid rgba(146, 64, 14, 0.1);
        border-radius: 4px;

        p {
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            width: 299px;
            height: 41px;
            color: #92400e;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        button {
            width: 343px;
        }
    }
}

.success--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &__icon {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        display: grid;
        place-items: center;
        margin-top: 62px;
    }

    &__content {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */

            text-align: center;

            color: #000000;
        }
        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 20px;
            text-align: center;

            /* gray/2 */

            color: #969696;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;
    }
}
