.phonebook--container {
    position: relative;
    &__lists {
        display: flex;
        flex-direction: column;

        gap: 8px;
        padding: 20px;
        overflow-y: scroll;
        height: 72vh;
        .loader {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            margin-top: 3rem;
            height: 12vh;
        }
        .list {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 16px;
            gap: 8px;
            height: 50px;
            background: #ffffff;
            border: 1px solid rgba(216, 216, 216, 0.7);
            border-radius: 8px;

            flex: none;
            order: 0;
            flex-grow: 0;

            .right--side {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
                .icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    background: rgba(235, 41, 49, 0.12);
                    display: grid;
                    place-items: center;
                    font-style: normal;
                    font-weight: 450;
                    font-size: 12px;
                    line-height: 20px;
                    /* identical to box height, or 170% */

                    /* primary/hover */

                    color: #bc0c13;
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    p {
                        margin: 0;
                        padding: 0;
                    }

                    .title {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        /* identical to box height, or 170% */

                        color: #000000;

                        /* Inside auto layout */

                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
                    .contacts {
                        font-style: normal;
                        font-weight: 450;
                        font-size: 12px;
                        line-height: 20px;
                        color: #969696;
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                }
            }
        }
    }
    .cta--dialog {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100px;
        position: fixed;
        bottom: 155px;
        right: 16px;
        height: 82px;

        button {
            width: 100%;
            height: 41px;
        }
    }
    .float--cta {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        background: #eb2931;
        display: grid;
        place-items: center;
        position: fixed;
        bottom: 95px;
        right: 16px;
    }

    .loading {
        position: fixed;
        bottom: 95px;
        left: 16px;
        font-size: 24px;
    }
}

.notfound--container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;

    &__icon {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        background: #eff6ff;
        display: grid;
        place-items: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            /* identical to box height, or 150% */

            text-align: center;

            color: #000000;

            /* Inside auto layout */

            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
        }

        p {
            width: 290.87px;
            height: 40px;
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            text-align: center;

            /* gray/2 */

            color: #969696;

            /* Inside auto layout */

            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }
    &__buttons {
        display: flex;
        justify-content: center;
        gap: 7px;
        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
        }
    }
}

@media screen and (min-width: 1440px) {
    .phonebook--container {
        .cta--dialog {
            position: absolute;
            bottom: 70px;
        }

        .float--cta {
            position: absolute;
            bottom: 10px;
        }
    }
}
