.appbars--container {
    width: 100%;
    background: transparent;
    box-shadow: 0px -1px 4px rgba(148, 148, 148, 0.1);
    position: fixed;
    bottom: 0;
    overflow: hidden;
    border-radius: 4px 4px 0px 0px;
    display: flex;
    justify-content: center;

    &__navs {
        display: flex;
        justify-content: space-between;
        width: 375px;
        height: 49px;
        padding: 1rem;
        background: #fff;
        .not-active {
            text-decoration: none;

            .nav {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0px;
                gap: 4px;
                width: 33px;
                height: 49px;
                cursor: pointer;
                .icon {
                    width: 24px;
                    height: 24px;

                    svg {
                        fill: rgba(150, 150, 150, 1);
                        stroke: rgba(150, 150, 150, 1);
                        fill-opacity: 0.2;
                        path {
                            stroke: rgba(150, 150, 150, 1);
                            fill: rgba(150, 150, 150, 1);
                            fill-opacity: 0.2;
                        }
                    }
                }

                .title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    color: #969696;
                }
            }
        }

        .active {
            text-decoration: none;
            .nav {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0px;
                gap: 4px;
                width: 33px;
                height: 49px;
                cursor: pointer;
                .title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 20px;
                    text-align: center;
                    color: #eb2931;
                }

                .icon {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }
}
