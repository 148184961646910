.signup--container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__header {
    display: flex;
    justify-content: center;
    .login__container--img {
      p {
        width: 90px;
        height: 37px;
        font-family: "Comfortaa";
        font-style: normal;
        font-weight: 400;
        font-size: 27.7631px;
        line-height: 36px;
        /* or 130% */

        /* neutral / black */
        color: #eb2931;
        span {
          width: 90px;
          height: 37px;
          font-family: "Comfortaa";
          font-style: normal;
          font-weight: 400;
          font-size: 27.7631px;
          line-height: 36px;
          /* or 130% */

          color: #000000;
        }
      }
    }
  }

  &__aside {
    width: 375px;
    height: 80vh;
  }
}

@media screen and (width: 375px) {
  .signup--container {
    &__aside {
      width: 343px;
    }
  }
}
