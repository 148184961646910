.gift-type--container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    font-family: "circular_std_book";
    &__type {
        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;
            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            color: #969696;
        }

        .gift--types {
            display: flex;
            flex-direction: column;
            align-items: center;

            gap: 10px;
            margin-top: 40px;
            &__item {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 16px;
                height: 40px;
                width: 90%;
                background: #ffffff;
                border: 1px solid rgba(216, 216, 216, 0.7);
                border-radius: 8px;

                div {
                    display: flex;
                    gap: 20px;

                    .first-icon {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background: #f0fdf4;
                        display: grid;
                        place-items: center;
                        cursor: pointer;
                        img {
                            width: 24px;
                            width: 24px;
                        }
                    }
                    .last-icon {
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        background: #eff6ff;
                        display: grid;
                        place-items: center;
                        cursor: pointer;
                        img {
                            width: 24px;
                            width: 24px;
                        }
                    }
                    p {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        /* identical to box height, or 170% */

                        color: #000000;
                    }
                }
            }

            .divider {
                font-family: "circular_std_book";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 170% */

                letter-spacing: -0.01em;

                /* gray/2 */

                color: #969696;
            }
        }
    }
}
