.dashboard--container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #f6f7f8;
    &__items {
        padding: 0px 20px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 15px;
        position: relative;
        height: 175px;
        .item {
            width: 375px;
            flex: 2;
            border-radius: 8px;
            cursor: pointer;

            img {
                width: 100%;
                object-fit: cover;
            }
        }

        &::before {
            position: absolute;
            content: "";
            width: 289px;
            height: 132px;
            left: 43px;
            top: 95px;

            background: rgba(17, 23, 31, 0.15);
            filter: blur(30px);
            border-radius: 10px;
        }
    }
    &__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        margin-top: 26px;
        .testimonial--control {
            width: 6px;
            height: 6px;
            background: #d9d9d9;
            border-radius: 50%;
            cursor: pointer;
        }
        .testimonial--control__active {
            width: 6px;
            height: 6px;
            background: #eb2931;
            border-radius: 50%;
            cursor: pointer;
        }
    }
    &__recent-activities {
        margin-top: 24px;

        .title {
            padding: 0px 20px;
            height: 31px;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 31px;

            color: #000000;
        }

        .transaction--histories {
            overflow: scroll;
            height: 380px;
            background: #ffffff;
            margin-bottom: 1rem;

            .history {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px 20px;
                gap: 16px;
                .icon {
                    display: grid;
                    place-items: center;
                    width: 42px;
                    height: 42px;
                    background: #fbfbfb;
                    border-radius: 100%;
                }

                .content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    h3 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;

                        color: #000000;
                    }
                    p {
                        display: flex;
                        gap: 13px;
                        padding: 0;
                        margin: 0;
                        span {
                            font-style: normal;
                            font-weight: 450;
                            font-size: 12px;
                            line-height: 20px;
                            /* identical to box height, or 170% */

                            text-align: center;

                            /* gray/2 */

                            color: #969696;
                        }
                    }

                    .amount {
                        font-style: normal;
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 31px;
                        text-align: center;
                    }
                }
            }

            .loader {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                height: 180px;
            }
        }
        .loading {
            position: fixed;
            bottom: 80px;
            font-size: 24px;
            background-color: #fff;
            width: 100vw;
            text-align: center;
            padding-top: 20px;
            height: 40px;
        }
    }

    &__buttons {
        margin-top: 24px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 7px;
        z-index: 1;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 9px;
        }
    }
}

.inactive {
    display: none;
}

.wallet--card-day {
    height: 185px;
    background: url("../../assets/images/day.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    position: relative;
    // overflow: hidden;
    margin-top: 9px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;

    p {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        text-align: center;

        /* gray/3 */

        color: #d8d8d8;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 55px;
        text-align: center;
        color: #f4f4f4;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px;
    }
}

.wallet--card-night {
    height: 185px;
    background: url("../../assets/images/night.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    position: relative;
    // overflow: hidden;
    margin-top: 9px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;

    p {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        text-align: center;

        /* gray/3 */

        color: #d8d8d8;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }

    h2 {
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 55px;
        text-align: center;
        color: #f4f4f4;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px;
    }
}

@keyframes cardSlideRight {
    from {
        // transform: translate3d(-8.7rem, 0, 1rem);
        transform: translateX(-500px);
    }

    to {
        // transform: translate3d(0);
        transform: translateX(0px);
    }
}

@keyframes cardSlideLeft {
    from {
        // transform: translate3d(-8.7rem, 0, 1rem);
        transform: translateX(500px);
    }

    to {
        // transform: translate3d(0);
        transform: translateX(-0px);
    }
}
.next {
    animation: cardSlideRight 0.5s ease-out 0s forwards;
}
.prev {
    animation: cardSlideLeft 0.5s ease-out 0s backwards;
}

.info--modal {
    width: 343px;
    background: #ffffff;
    border-radius: 8px;

    &__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;

        .summary {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 8px;

            .title {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                /* identical to box height, or 170% */

                /* gray/2 */

                color: #969696;
            }

            .info {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                text-transform: capitalize;
                margin-top: -4px;
                color: #000000;
            }

            .failed {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                text-transform: capitalize;

                color: #dc2626;
            }

            .pending,
            .new {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                text-transform: capitalize;
                color: #f59e0b;
            }

            .success,
            .sent,
            .processed {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                text-transform: capitalize;

                color: #16a34a;
            }
        }
    }
}
