@import "../../styles/base.scss";

.container,
.footer__row,
.social__links {
  display: flex;
}

.container {
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0rem auto;
  width: 90%;
  padding: 4rem 0;

  .footer__row {
    .col:first-child {
      display: flex;
      flex-direction: column;
      img {
        width: 165.5px;
        height: 80.75px;
      }
    }
  }
}

footer {
  background: #11171f;
  color: #ffffff;
  position: relative;
  z-index: 1;
  .footer__row {
    width: 100%;
    flex-flow: wrap;
    justify-content: space-between;

    .col {
      .logo {
        p {
          width: 90px;
          height: 37px;
          font-family: "Comfortaa";
          font-style: normal;
          font-weight: 400;
          font-size: 44.2708px;
          line-height: 58px;
          /* or 130% */

          /* neutral / black */
          color: #eb2931;
          span {
            width: 90px;
            height: 37px;
            font-family: "Comfortaa";
            font-style: normal;
            font-weight: 400;
            font-size: 44.2708px;
            line-height: 58px;

            color: #fff;
          }
        }

        width: 100%;
        display: flex;
        justify-content: space-between;

        .burger--icon {
          display: block;
        }
      }
      p {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 31px;
        padding-bottom: 18px;

        color: #d8d8d8;
      }
      .products-list {
        padding: 0px;
        li {
          list-style: none;
          span,
          a {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            margin: 0;
            color: #d8d8d8;
          }
        }
      }
      .social__links {
        padding: 0px;
        li {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 40px;
        }
      }

      .contact-links {
        padding: 0px;
        li {
          list-style: none;
          span {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            margin: 0;

            color: #d8d8d8;
          }
        }
      }
      .legal-links {
        li {
          span {
            font-style: normal;
            font-weight: 450;
            font-size: 16px;
            line-height: 31px;
            margin: 0;

            color: #d8d8d8;
          }
        }
      }
    }
  }

  .footer__bottom--text {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 4%;

    .socials {
      .social__links {
        li {
          margin-right: 45px;
        }
      }
    }
    .footer__copyright {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      border-top: 1px solid rgba(150, 150, 150, 0.2);
      padding-top: 20px;
      span {
        margin-top: 15px;
      }
      margin-right: auto;
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 600px) {
  footer {
    .footer__row {
      .col {
        .logo {
          p {
            width: 143px;
            height: 58px;
          }
        }
        p {
          margin-bottom: -20px;
        }
      }
    }
  }
}

@media (max-width: $break-point-xl) {
  footer {
    .footer__row {
      .col {
        width: 100%;
        margin-bottom: 15%;

        p {
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 31px;
          /* identical to box height, or 153% */

          /* gray/4 */
          padding-bottom: 0px;

          color: #f4f4f4;
        }
      }

      ul {
        // flex-wrap: wrap;
        margin-top: 2rem;

        li {
          margin-right: 5%;
          flex-direction: column;
          font-style: normal;
          font-weight: 450;
          font-size: 16px;
          line-height: 31px;
          /* identical to box height, or 191% */

          /* gray/3 */

          color: #d8d8d8;
        }
      }

      p,
      address {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .footer__bottom--text {
      flex-direction: column;
      align-items: center;

      .footer__copyright {
        margin-bottom: 10%;
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 31px;
        /* identical to box height, or 219% */

        /* gray/4 */

        color: #f4f4f4;
      }

      .footer__privacy {
        color: $text-light;
      }
    }
  }
}
