.view--container {
    &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
        position: relative;

        .phonebook--name {
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 36px;
            text-align: center;

            color: #000000;
        }
        .icon {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            display: grid;
            place-items: center;
            background: #ffffff;
        }

        .view--box {
            position: absolute;
            right: 0px;
            top: 40px;
            width: 147px;
            height: 68px;

            background: #ffffff;
            border: 1px solid rgba(216, 216, 216, 0.7);
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 24px;
            padding: 12px;

            .add {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 8px;

                width: 146px;
                height: 20px;
                flex: none;
                order: 0;
                flex-grow: 0;

                span {
                    font-style: normal;
                    font-weight: 450;
                    font-size: 14px;
                    line-height: 20px;
                    /* identical to box height, or 143% */

                    /* gray/1 */

                    color: #323232;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }

            .delete {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 8px;
                width: 145px;
                height: 20px;
                flex: none;
                order: 1;
                flex-grow: 0;

                span {
                    font-weight: 450;
                    font-size: 14px;
                    line-height: 20px;

                    color: #bc0c13;
                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }
    }

    &__lists {
        display: flex;
        flex-direction: column;
        gap: 12px;

        overflow-y: scroll;
        height: 460px;
        .list {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px;
            gap: 16px;

            height: 49px;
            flex: none;
            order: 0;
            flex-grow: 0;

            .content {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 16px;

                height: 49px;

                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 1;

                .icon {
                    width: 32px;
                    height: 32px;
                    background: #ffffff;
                    border-radius: 100%;
                    display: grid;
                    place-items: center;
                    font-style: normal;
                    font-weight: 450;
                    font-size: 12px;
                    line-height: 20px;
                    /* identical to box height, or 170% */

                    /* info / 800 */

                    color: #1e40af;
                }

                .details {
                    p {
                        margin: 0;
                        padding: 0;
                    }

                    .name {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 24px;
                        /* identical to box height, or 170% */

                        /* gray/1 */

                        color: #323232;

                        /* Inside auto layout */

                        flex: none;
                        order: 0;
                        flex-grow: 0;
                    }
                    .contact {
                        font-style: normal;
                        font-weight: 450;
                        font-size: 12px;
                        line-height: 20px;
                        /* identical to box height, or 170% */

                        /* gray/2 */

                        color: #969696;

                        /* Inside auto layout */

                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                }
            }
        }
    }
    .loader {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 180px;
    }
}

.deleteModal {
    width: 75vw;
    height: 158px;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    .buttons {
        margin-top: 24px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 8px;
        button {
            width: 108px;
            height: 42px;
            padding: 5px;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;

            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }

    h3 {
        // width: 161px;
        height: 31px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 31px;
        color: #000000;
        flex: none;
        order: 0;
        flex-grow: 0;
    }
    p {
        width: 225px;
        height: 20px;

        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 20px;
        color: #969696;
    }
}
