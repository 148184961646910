@import "../../styles/base.scss";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .form__container {
    width: 100%;
    padding: 20px;
    border-radius: 20px;

    h3 {
      font-weight: 500;
      font-size: 18px;
      line-height: 31px;
      color: #000000;
    }

    p {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 20px;
      color: #969696;
    }

    form {
      width: 100%;

      .form--fields {
        height: 320px;
        overflow-y: auto;
        margin-bottom: 5rem;
      }
      label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 31px;
        color: #000000;
        margin-bottom: 0px;
      }

      input {
        background: #fbfbfb;
      }
    }
    .button {
      font-family: "circular_std_book";
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      position: fixed;
      bottom: 20px;
      left: 16px;
      right: 16px;
      display: flex;
      flex-direction: column;
      align-items: center;

      button {
        width: 375px;
      }
      .login__container--links {
        justify-content: center;

        p {
          font-style: normal;
          font-style: normal;
          font-weight: 450;
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #000000;
          span {
            color: #126abd;
            font-weight: 700;
          }
        }

        a {
          text-decoration: none;
          display: initial;

          &:hover > p {
            color: $primary-color;
          }
        }
      }
    }
  }
}
.login__container,
.resetpassword__container,
.login__container--form,
.resetpassword__container--form,
.login__container--links {
  display: -ms-flex;
  display: -moz-flex;
  display: flex;
}

.login__container,
.resetpassword__container {
  height: 100vh;
  overflow: hidden;
  align-items: stretch;
  flex-direction: row;
}

.login__container--img,
.resetpassword__container--img {
  img {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  width: 50%;
  // background-image: url(../../../assets/images/msa__login--image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

.login__container--form,
.resetpassword__container--form,
.forgotpassword__form {
  input {
    padding: 1.4rem;

    border-radius: $border-radius;
    font-size: 38px;
    position: relative;

    &:focus {
      border: 1px solid $primary-color;
      outline: none;
    }
  }
}

.login__container--form,
.resetpassword__container--form {
  h3 {
    font-size: 30px;
    line-height: 32px;
    align-items: center;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    color: $text-light;
  }
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 5rem 10rem;
}

hr {
  border: 1px solid #f5f5f5;
  clear: both;
  display: block;
  width: 100%;
  height: 0.5px;
}

input[type="placeholder"] {
  font-style: normal;
  font-weight: 500;
  color: $text-light;
}

button {
  font-family: "circular_std_book";
  height: 50px;
  width: 100%;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 19px;
  background-color: $primary-color;
  border-radius: $border-radius;
  outline: none;
  box-shadow: none;
  border: 1px solid transparent;
  transition: 0.1s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }
}

label {
  color: $label-color;
  font-style: bold;
  font-weight: 500;
  font-size: 18px;
  line-height: 50px;
}

.btn--disabled {
  cursor: not-allowed;
  background: rgba($primary-color, 0.8);
}

.toggle__password {
  position: absolute;
  top: 45px;
  right: 5%;
  cursor: pointer;
  transition: 0.1s ease-in-out;

  img {
    width: 20px;
    height: 20px;
  }
}

.login__container--forgotpassword {
  float: right;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;

  color: #126abd;
  cursor: pointer;

  form {
    input {
      height: 60px;
    }
  }
  &:hover {
    font-weight: 800;
  }
}

.login__container--links {
  justify-content: center;
  width: 100%;

  p {
    font-style: normal;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;

    span {
      color: #126abd;
    }
  }

  a {
    text-decoration: none;
    display: initial;

    &:hover > p {
      color: $primary-color;
    }
  }
}

.forgotpassword__modal--content {
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 36px;
  }
}

.forgotpassword__form {
  button {
    margin: 1.5rem 0;
  }

  p {
    font-size: 16px;
    color: $text-light;
  }
}

@media (max-width: 768px) {
  .login__container--img,
  .resetpassword__container--img {
    display: none;
  }

  .login__container--form,
  .resetpassword__container--form {
    width: 100%;
    padding: 3rem 1.5rem;
  }

  .login__container--links {
    // margin-top: 2rem;
  }
}
