@import "../base.scss";
input {
  accent-color: $primary-color;
  background: #fbfbfb;
  /* gray/4 */

  border: 1px solid #f4f4f4;
  box-sizing: border-box;
  border-radius: 4px;
}

.signup__container,
.signup__container--aside,
.signup__container--aside header,
.form__container--custom,
.form__container--row,
.form__container--row-secondary {
  display: flex;
  // grid-template-columns: repeat(2,auto);
  //   border: 1px solid red;
  // min-height: 100vh;
}
// .signup__container{
//   display: grid;
//   grid-template-columns: 400px auto;
// }

.resend {
  font-size: 14px;
  cursor: pointer;
  span {
    font-size: 14px;
    color: $primary-color;
  }
}

.signup__container {
  min-height: 100vh;
}

.signup__container--left {
  // background: url("../../assets/images/msa__login--image.jpg") no-repeat;
  background-size: cover;
  // background-position: center;
  min-height: 100vh;
  min-width: 50%;
}

.signup__container--aside header,
.form__container--row,
.form__container--row-secondary {
  justify-content: space-between;
}

.signup__container--aside {
  align-items: center;
  justify-content: center;
  h2 {
    font-size: 30px;
    width: fit-content;
    margin: 0;
  }

  header {
    align-items: center;
    height: 100px;
    width: 100%;

    div:nth-child(2n) {
      p {
        &:first-child {
          color: $label-color;
          margin: 0 0 0.5rem 0;
          font-size: 14px;
        }
      }
    }
  }
  flex-direction: column;
  flex: 1;
  padding: 2rem 10rem;
  transition: all ease-in 0.35s;

  // width: 100%;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3% 0%;
  .signup {
    width: 610px;
    border-radius: 20px;

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 31px;
      /* identical to box height, or 170% */

      color: #000000;
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    p {
      font-style: normal;
      font-weight: 450;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      /* gray/2 */

      color: #969696;
    }
    form {
      margin-top: 1rem;
      width: 100%;
      // box-sizing: border-box;
      .form--wrapper {
        height: 58vh;
        overflow-y: scroll;
      }

      .button {
        font-family: "circular_std_book";
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        // position: fixed;
        // bottom: 50px;
        // left: 16px;
        // right: 16px;
        // width: 92%;
      }

      label {
        font-family: "circular_std_book";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 31px;
        color: #000000;
      }

      .form__container--col {
        & > div {
          margin-top: 20px;
          width: 100%;
          position: relative;
          span {
            position: absolute;
            right: 0;
            color: $label-color;
            display: inline-block;
            margin: 1rem 0 0 0;

            img {
              width: 20px;
              height: 20px;
            }
          }
        }
        input {
          background-color: #fbfbfb;
          border: 1px solid #f4f4f4;
          box-sizing: border-box;
          border-radius: 4px;
        }

        input:first-child {
          width: 100%;
          // height: 40px;
        }

        select {
          width: 100%;
          background-color: #fbfbfb;
          border: 1px solid #f4f4f4;
          box-sizing: border-box;
          border-radius: 4px;
        }

        .radio--buttons {
          display: flex;
          align-items: baseline;

          label {
            font-family: "circular_std_book";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 31px;
            // width: 250px;
            letter-spacing: 0.2px;
            padding-left: 5px;
            color: #000000;
          }

          .radio {
            display: flex;
            align-items: center;
            width: 240px;
          }
          .radio:last-child {
            margin-right: 120px !important;
          }
        }
      }

      .form__container--row,
      .form__container--row-secondary {
        margin-top: 5px;
        & > div {
          // border: 1px solid green;
          // margin: 0 0 0 0;
          position: relative;
          width: 47%;
          span {
            color: $label-color;
            display: inline-block;
            margin: 1.3rem 0 0 0;
          }
        }
        li,
        label {
          font-family: "circular_std_book";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 31px;
          color: #000000;
        }

        input,
        select {
          font-size: 14px;
          padding: 1rem;
          // height: 40px;
          width: 100%;
          background-color: #fbfbfb;
          /* gray/4 */

          border: 1px solid #f4f4f4;
          box-sizing: border-box;
          border-radius: 4px;
        }

        input:focus {
          // box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
          transition: all ease 0.35s;
          outline: none;
        }

        select {
          appearance: none;
          // background: url("../../assets/icons/dropdown-arrow.svg") no-repeat;
          background-position: right 0.9rem center;

          &::after {
            content: " ▾";
            border: 4px solid transparent;
          }
        }
      }

      .form__container--custom {
        // border: 1px solid $border-color;
        border-radius: 6px;
        position: relative;

        select,
        input {
          background-color: #fbfbfb;
          // height: 40px !important;
          border: 1px solid #f4f4f4;
          box-sizing: border-box;
          border-radius: 4px;
        }

        select {
          margin-right: 10px;
          width: 102px;
          padding-top: 0px;
          padding-bottom: 0px;
          // border-right: 1px solid $border-color;
        }

        .placeholder {
          background-color: #fbfbfb;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          width: 102px;
          // height: 40px;
          border: 1px solid #f4f4f4;
          border-radius: 4px;
          p {
            font-style: normal;
            font-weight: 450;
            font-size: 18px;
            line-height: 31px;
            /* identical to box height, or 170% */
            text-align: center;
            color: #000000;
          }
        }

        span {
          cursor: pointer;
          display: inline-block;
          padding: 0 1rem;
        }

        input {
          flex: 1;
          width: 70%;
        }
      }
      .form__container--row-secondary,
      .form__container--row-terms {
        // border: 1px solid red;
        & > div {
          width: 100%;
        }

        input,
        select {
          font-size: 14px;
          padding: 1rem;
          // height: 40px;
          width: 100%;
          background-color: #fbfbfb;
          /* gray/4 */

          border: 1px solid #f4f4f4;
          box-sizing: border-box;
          border-radius: 4px;
        }
      }

      .form__container--row-terms {
        & > div {
          // display: flex;
          input {
            width: fit-content;
            background: #fbfbfb;
            /* gray/4 */

            border: 1px solid #f4f4f4;
            box-sizing: border-box;
            border-radius: 4px;
            accent-color: red;
          }
        }
        // height: 45px;
      }

      .terms {
        margin-top: 5px;
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 170% */

        color: #000000;
        a {
          display: initial;
          text-decoration: none;
        }
      }

      button {
        margin-top: 1rem;
      }
    }

    .signup__container--links {
      width: 100%;
      // position: fixed;
      // bottom: 10px;

      p {
        font-style: normal;
        font-weight: 450;
        font-size: 18px;
        line-height: 31px;
        /* identical to box height, or 170% */
        text-align: center;
        color: #000000;

        span {
          color: #126abd;
          cursor: pointer;
        }
      }

      a {
        text-decoration: none;
        display: initial;

        &:hover > p {
          color: $primary-color;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .signup__container--left {
    display: none;
  }
  .signup__container--aside {
    padding: 1rem 2rem;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .container {
    padding-top: 0px;
    .signup {
      width: 100%;
      border-radius: 10px;
      // padding: 20px;
      overflow-x: hidden;
      margin-bottom: 30px;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        width: 290.87px;
      }

      .subheading {
        p {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          width: 290.87px;
        }

        div {
          width: 20px;
          height: 20px;

          svg {
            width: 15px;
            height: 15px;
          }
        }
      }

      form {
        .form__container--col {
          .radio--buttons {
            width: 354px !important;

            display: flex;
            .radio:last-child {
              position: absolute;
              left: 180px;
            }
          }
        }
        label {
          font-family: "circular_std_book";
          font-style: normal;
          font-weight: 600;
          padding-top: 5px;
          font-size: 14px;
          line-height: 17px;
          /* identical to box height, or 120% */

          color: #000000;
        }

        button {
          font-size: 14px;
          line-height: 13px;
        }
      }
    }
  }

  .reset--success {
    margin-top: 3rem;
    width: 334.77px;
    padding: 10px 20px;
    border-radius: 10px;

    form {
      width: 100%;

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        width: 290.87px;
      }

      p {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 24px;
        width: 290.87px;
      }

      button {
        font-size: 14px;
        line-height: 13px;
        font-weight: 600;
        width: 290.87px;
      }
    }
  }
}
