.layout--container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
}

@media screen and (min-width: 1290px) and (max-width: 1390px) {
    .layout--container {
        justify-content: unset;
        flex-direction: row;

        main {
            overflow: hidden;
        }
    }
}

@media screen and (max-width: 600px) {
    .layout--container {
        flex-direction: column;
        overflow-y: hidden;
    }
}
