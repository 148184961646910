.file-upload--container {
    margin-top: 24px;
    // width: 343px;
    height: 221px;
    background: #ffffff;
    border: 1px dashed #1d4ed8;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        /* identical to box height, or 170% */

        text-align: center;

        width: 231px;
        color: #969696;

        margin: 0;

        flex: none;
        order: 1;
        flex-grow: 0;
    }
    &__icon {
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: #f4f4f4;
        display: grid;
        place-items: center;
    }

    input {
        display: none;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    input + label * {
        pointer-events: none;
    }
    .re-upload {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        width: 80px;
        height: 41px;
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        padding: 0.5rem;
        cursor: pointer;
    }
}
