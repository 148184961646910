.why--container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 60px;

    background: #f6f7fb;
    margin-top: 50px;

    h3 {
        font-family: "CabinetGrotesk_medium";
        font-style: normal;
        font-weight: 700;
        font-size: 42px;
        line-height: 55px;
        /* identical to box height, or 130% */

        letter-spacing: -0.01em;

        color: #000000;
    }

    .mobile {
        display: none;
        width: 1313px;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: space-between;
        .why-box {
            box-sizing: border-box;
            width: 427px;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: #ffffff;
            /* gray/3 */

            border: 1px solid #d8d8d8;
            border-radius: 10px;
            box-shadow: 10px 10px 19px 0px rgba(95, 86, 86, 0.23);
            -webkit-box-shadow: 10px 10px 19px 0px rgba(95, 86, 86, 0.23);
            -moz-box-shadow: 10px 10px 19px 0px rgba(95, 86, 86, 0.23);

            .why-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                padding: 0px;
                gap: 16px;

                width: 367px;

                p {
                    width: 367px;
                    font-family: "circular_std_book";
                    font-style: normal;
                    font-weight: 450;
                    font-size: 24px;
                    line-height: 36px;
                    /* or 150% */

                    letter-spacing: -0.01em;

                    color: #000000;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }

        .show--more {
            display: none;
        }
    }

    .why--lists {
        display: none;
        width: 1313px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: space-between;
        .why-box {
            box-sizing: border-box;
            width: 427px;
            height: 200px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: #ffffff;
            /* gray/3 */

            border: 1px solid #d8d8d8;
            border-radius: 10px;
            box-shadow: 10px 10px 19px 0px rgba(95, 86, 86, 0.23);
            -webkit-box-shadow: 10px 10px 19px 0px rgba(95, 86, 86, 0.23);
            -moz-box-shadow: 10px 10px 19px 0px rgba(95, 86, 86, 0.23);

            .why-item {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                padding: 0px;
                gap: 16px;

                width: 367px;

                p {
                    width: 367px;
                    font-family: "circular_std_book";
                    font-style: normal;
                    font-weight: 450;
                    font-size: 24px;
                    line-height: 36px;
                    /* or 150% */

                    letter-spacing: -0.01em;

                    color: #000000;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }

        .show--more {
            display: none;
        }
    }
}

.testimonial--container {
    margin-top: 20px;
    margin-bottom: 177px;
    margin-top: 146px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
            font-family: "CabinetGrotesk_medium";
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 55px;

            letter-spacing: -0.02em;

            /* grey / 900 */

            color: #0f172a;
        }

        p {
            font-family: "circular_std_book";
            font-style: normal;
            font-weight: 450;
            font-size: 20px;
            line-height: 36px;
            /* identical to box height, or 180% */

            /* gray/2 */

            color: #969696;
        }
    }

    &__lists {
        margin-top: 65px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 20px;

        width: 1312px;

        .item {
            padding: 20px;
            width: 424px;
            height: 209px;
            background: #ffffff;
            border: 1px solid #d8d8d8;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .customer--info {
                display: flex;
                gap: 16px;

                .image {
                    width: 56px;
                    height: 56px;
                    border-radius: 100%;
                }

                .details {
                    .name {
                        font-family: "circular_std_book";
                        font-style: normal;
                        font-weight: 450;
                        font-size: 16px;
                        line-height: 36px;
                        color: #000000;

                        flex: none;
                        order: 0;
                        flex-grow: 0;
                        padding: 0;
                        margin: 0;
                    }
                    .role {
                        font-family: "circular_std_book";
                        font-style: normal;
                        font-weight: 450;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        /* gray/2 */

                        color: #969696;

                        /* Inside auto layout */
                        padding: 0;
                        margin: 0;
                        flex: none;
                        order: 1;
                        flex-grow: 0;
                    }
                }
            }
        }
    }
}

.banner--container {
    height: 660px;

    background: #ffffff;

    /* Inside auto layout */

    flex: none;
    order: 6;
    flex-grow: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    &__inner {
        width: 1312px;
        height: 514px;
        background-color: #f8b94f;
        background-image: url(../../../assets/images/mininal-background.svg);
        border-radius: 10px;

        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .right {
            width: 537px;
            height: 214px;
            display: flex;
            flex-direction: column;
            gap: 32px;

            h3 {
                width: 392px;
                height: 122px;

                font-family: "CabinetGrotesk_medium";
                font-style: normal;
                font-weight: 700;
                font-size: 48px;
                line-height: 61px;
                /* or 127% */

                letter-spacing: -0.02em;

                color: #000000;

                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;
            }

            button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 16px 24px;
                gap: 12px;
                width: 242px;
                height: 60px;
            }
        }

        .left {
            width: 607.74px;
            height: 755.64px;
            position: relative;
            img {
                position: absolute;
                bottom: 121px;
            }
        }
    }
}

.faq--wrapper {
    margin-top: 102px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 83px 0px;
    .faq--container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 1440px;

        &__right {
            position: relative;
            .faq--image {
                border-radius: 8px;
            }
            .top--icon {
                position: absolute;
                width: 131px;
                height: 131px;
                left: 454px;
                top: -60px;
            }
        }

        &__left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 36px;

            .faqs {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0px;
                gap: 24px;

                .item {
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid #cbd5e1;
                    width: 648px;

                    .header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;
                        width: 648px;

                        .question {
                            font-family: "circular_std_book";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 20px;
                            line-height: 36px;
                            /* identical to box height, or 180% */

                            /* grey/1 */

                            color: #0f172a;
                        }

                        .icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 32px;
                            height: 32px;
                            background: #f8fafc;
                            border-radius: 100%;

                            img {
                                height: 28px;
                                width: 24px;
                            }
                        }
                    }

                    .answer {
                        font-family: "circular_std_book";
                        font-style: normal;
                        font-weight: 450;
                        font-size: 16px;
                        line-height: 31px;
                        /* identical to box height, or 191% */

                        letter-spacing: -0.01em;

                        /* grey/3 */

                        color: #64748b;
                    }
                }
            }

            .heading {
                h3 {
                    font-family: "CabinetGrotesk_medium";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 42px;
                    line-height: 55px;

                    line-height: 55px;
                    /* identical to box height, or 130% */

                    letter-spacing: -0.01em;

                    color: #000000;
                }
                p {
                    font-family: "circular_std_book";
                    font-style: normal;
                    font-weight: 450;
                    font-size: 18px;
                    line-height: 31px;
                    /* identical to box height, or 170% */

                    /* gray/2 */

                    color: #969696;
                }
            }
        }
    }
}

.gifts--wrapper {
    height: 1194px;
    background: #030a12;
    display: flex;
    justify-content: center;
    align-items: center;

    .gifts--container {
        width: 1440px;

        h3 {
            font-family: "CabinetGrotesk_medium";
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 55px;
            /* identical to box height, or 130% */

            letter-spacing: -0.01em;

            color: #ffffff;
            text-align: center;
        }

        .gift--lists {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            gap: 22px;
            margin-top: 60px;
            &__item {
                box-sizing: border-box;

                width: 644px;
                height: 416px;

                background-color: #0d141c;
                border: 1px solid rgba(50, 50, 50, 0.7);
                border-radius: 20px;

                background-image: url(../../../assets/images/illustration.svg);
                background-repeat: no-repeat;
                background-size: contain;

                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    width: 205px;
                    font-family: "circular_std_book";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 44px;
                    line-height: 55px;
                    /* identical to box height, or 114% */
                    text-align: center;
                    letter-spacing: -0.01em;

                    color: #ffffff;
                }
            }
            &__item-two {
                box-sizing: border-box;

                width: 644px;
                height: 416px;

                background-color: #0d141c;
                border: 1px solid rgba(50, 50, 50, 0.7);
                border-radius: 20px;

                background-image: url(../../../assets/images/illustration-two.svg);

                background-repeat: no-repeat;
                background-size: contain;

                display: flex;
                justify-content: center;
                align-items: center;

                p {
                    width: 205px;
                    font-family: "circular_std_book";
                    font-style: normal;
                    font-weight: 700;
                    font-size: 44px;
                    line-height: 55px;
                    /* identical to box height, or 114% */
                    text-align: center;
                    letter-spacing: -0.01em;

                    color: #ffffff;
                }
            }
        }
    }
}

.how--wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .how--container {
        width: 1440px;
        height: 844px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        .how--lists {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
            height: 740.49px;
            &__item {
                box-sizing: border-box;
                width: 427px;
                height: 452px;

                /* neutral / white */

                background: #ffffff;
                /* gray/3 */

                border: 1px solid #d8d8d8;
                border-radius: 10px;
                img {
                    width: 100%;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                }

                p {
                    width: 363px;
                    height: 62px;
                    padding: 0px 32px;
                    font-style: normal;
                    // font-family: "circular_std_book";
                    font-weight: 450;
                    font-size: 20px;
                    line-height: 31px;
                    /* or 153% */

                    letter-spacing: -0.02em;

                    color: #000000;
                }
            }
        }

        h3 {
            font-family: "CabinetGrotesk_medium";
            font-style: normal;
            font-weight: 700;
            font-size: 42px;
            line-height: 55px;
        }
    }

    .how--lists::before {
        content: url(../../../assets/landingpage-icons/up-arrow.svg);
        position: absolute;
        top: 70px;
        left: 380px;
    }
    .how--lists::after {
        content: url(../../../assets/landingpage-icons/dow-arrow.svg);
        position: absolute;
        bottom: 40px;
        right: 395px;
    }
}

.hero--wrapper {
    height: 884px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hero--container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        width: 1440px;
        gap: 50px;

        &__left {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 32px;
            .hero--title {
                width: 648px;
                height: 192px;

                font-family: "CabinetGrotesk_black";
                font-style: normal;
                font-weight: 800;
                font-size: 64px;
                line-height: 64px;
                /* or 100% */

                letter-spacing: -0.02em;

                color: #000000;

                /* Inside auto layout */

                flex: none;
                order: 0;
                align-self: stretch;
                flex-grow: 0;
            }

            .hero--text {
                width: 648px;
                height: 72px;

                font-family: "circular_std_book";
                font-style: normal;
                font-weight: 450;
                font-size: 28px;
                line-height: 36px;
                /* or 129% */

                letter-spacing: -0.02em;

                /* gray/2 */

                color: #969696;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;
            }

            button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 16px 24px;
                gap: 12px;

                width: 205px;
                height: 60px;

                /* primary / active */

                background: #eb2931;
                border-radius: 4px;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;
            }

            .gifters {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0px;
                gap: 16px;

                width: 449px;
                height: 50px;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;
                p {
                    font-family: "circular_std_book";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 31px;
                    /* identical to box height, or 170% */

                    letter-spacing: -0.02em;

                    /* gray/1 */

                    color: #323232;
                }
            }
        }

        &__right {
            position: relative;
            width: 648px;
            .top--icon {
                position: absolute;
                right: -20px;
                top: -30px;
                width: 136px;
                height: 136px;
                border-radius: 100%;

                background: #1c1c1c;

                border: 4px solid #ffffff;

                display: grid;
                place-items: center;
            }
            .hero--image {
                width: 100%;
                border-radius: 10px;
            }

            .notification {
                box-sizing: border-box;

                position: absolute;
                width: 336px;
                height: 87.29px;
                right: 415px;
                top: 595px;

                background: #ffffff;
                /* gray/4 */

                border: 0.865248px solid #f4f4f4;
                box-shadow: 16px 5px 25px 9px rgba(0, 0, 0, 0.21);
                -webkit-box-shadow: 16px 5px 25px 9px rgba(0, 0, 0, 0.21);
                -moz-box-shadow: 16px 5px 25px 9px rgba(0, 0, 0, 0.21);
                border-radius: 8.65248px;

                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 0px;
                gap: 13.84px;

                .gift--icon {
                    width: 58.84px;
                    height: 58.84px;
                }

                p {
                    font-family: "circular_std_book";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 20.766px;
                    line-height: 26px;
                    /* identical to box height, or 127% */

                    letter-spacing: -0.02em;

                    color: #000000;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .why--container {
        .mobile {
            display: flex;
            width: 100%;
            justify-content: center;

            .why-box {
                width: 343.42px;
                height: 160.85px;

                .why-item {
                    width: 295.17px;
                    p {
                        width: 295.17px;
                        font-style: normal;
                        font-weight: 450;
                        font-size: 18px;
                        line-height: 29px;
                        /* or 161% */

                        letter-spacing: -0.01em;
                    }
                }
            }

            .show--more {
                display: block;
                width: 78px;
                height: 29px;

                font-family: "circular_std_book";
                font-style: normal;
                font-weight: 450;
                font-size: 16px;
                line-height: 29px;
                /* identical to box height, or 181% */

                text-align: center;
                letter-spacing: -0.01em;

                color: #000000;
                position: relative;
                cursor: pointer;
            }

            .show--more::after {
                content: url(../../../assets/landingpage-icons/more-arrow.svg);
                position: absolute;
                top: 0;
                right: -20px;
            }
        }

        .why--lists {
            display: none;
        }
    }

    .testimonial--container {
        width: auto;
        margin-top: auto;
        margin-bottom: 50px;
        &__header {
            margin-bottom: 18px;
            h3 {
                font-weight: 700;
                font-size: 32px;
                line-height: 55px;
                /* identical to box height, or 171% */

                letter-spacing: -0.02em;
            }

            p {
                font-style: normal;
                font-weight: 450;
                font-size: 16px;
                line-height: 36px;
            }
        }
        &__lists {
            margin-top: 18px;
            width: 328px;
            flex-direction: column;
            justify-content: center;

            .item {
                width: 308px;
                height: 219.04px;
            }
        }
    }

    .banner--container {
        &__inner {
            width: 343px;
            height: 510px;

            flex-direction: column;
            align-items: center;

            .right {
                width: auto;
                height: 148px;

                justify-content: center;
                align-items: center;
                h3 {
                    width: 238px;
                    height: 74px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 26px;
                    line-height: 37px;
                    /* or 116% */

                    text-align: center;
                    letter-spacing: -0.02em;

                    color: #000000;

                    /* Inside auto layout */

                    flex: none;
                    order: 0;
                    flex-grow: 0;
                }
            }

            .left {
                width: 305.62px;
                height: 280px;

                img {
                    bottom: -41px;
                    width: 305.62px;
                }
            }
        }
    }

    .faq--wrapper {
        height: auto;
        .faq--container {
            width: 100%;
            flex-direction: column-reverse;
            justify-content: center;
            &__right {
                display: none;
            }

            &__left {
                width: auto;
                .heading {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    h3 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 55px;
                        /* identical to box height, or 171% */

                        letter-spacing: -0.01em;
                    }

                    p {
                        display: none;
                    }
                }
                .faqs {
                    width: 343.78px;
                    .item {
                        width: 343.78px;
                        .header {
                            width: 343.78px;

                            align-items: baseline;

                            .question {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 19px;
                            }

                            .icon {
                                width: 20px;
                                height: 20px;
                                img {
                                    width: 10px;
                                    height: 10px;
                                }
                            }
                        }

                        .answer {
                            font-style: normal;
                            font-weight: 450;
                            font-size: 12px;
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }

    .gifts--wrapper {
        .gifts--container {
            h3 {
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 55px;
                /* identical to box height, or 171% */

                letter-spacing: -0.01em;
            }
            .gift--lists {
                &__item,
                &__item-two {
                    width: 353px;
                    height: 220px;

                    p {
                        width: 105px;
                        height: 59px;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 29px;
                        /* identical to box height, or 114% */

                        letter-spacing: -0.01em;
                    }
                }
            }
        }
    }

    .how--wrapper {
        height: 1416px;
        margin-top: 30px;
        .how--container {
            width: 100%;
            .how--lists {
                flex-direction: column;
                gap: 26px;
                height: 1167px;
                margin-top: 60px;
                &__item {
                    width: 343px;
                    height: 363px;
                    left: 13px;
                    top: 166.99px;

                    /* neutral / white */

                    background: #ffffff;
                    /* gray/3 */

                    border: 0.803097px solid #d8d8d8;
                    border-radius: 8.03097px;
                    transform: rotate(3deg);

                    p {
                        width: 291.52px;
                        height: 50px;
                        font-style: normal;
                        font-weight: 450;
                        font-size: 16px;
                        line-height: 25px;
                        /* or 154% */

                        letter-spacing: -0.02em;

                        color: #000000;
                    }

                    img {
                        width: 342.92px;
                        height: 281.08px;
                    }
                }
                &__item:first-child {
                    width: 343px;
                    height: 363px;
                    left: 13px;
                    top: 166.99px;

                    /* neutral / white */

                    background: #ffffff;
                    /* gray/3 */

                    border: 0.803097px solid #d8d8d8;
                    border-radius: 8.03097px;
                    transform: rotate(-1deg);

                    p {
                        width: 291.52px;
                        height: 50px;
                        font-style: normal;
                        font-weight: 450;
                        font-size: 16px;
                        line-height: 25px;
                        /* or 154% */

                        letter-spacing: -0.02em;

                        color: #000000;
                    }
                }

                &__item:last-child {
                    width: 343px;
                    height: 363px;
                    left: 13px;
                    top: 166.99px;

                    /* neutral / white */

                    background: #ffffff;
                    /* gray/3 */

                    border: 0.803097px solid #d8d8d8;
                    border-radius: 8.03097px;
                    transform: rotate(-2deg);

                    p {
                        width: 291.52px;
                        height: 50px;
                        font-style: normal;
                        font-weight: 450;
                        font-size: 16px;
                        line-height: 25px;
                        /* or 154% */

                        letter-spacing: -0.02em;

                        color: #000000;
                    }
                }
            }
        }

        .how--lists::after {
            content: url(../../../assets/landingpage-icons/down-mobile-arrow.svg);
            left: 20px;
            bottom: 105px;
        }

        .how--lists::before {
            content: url(../../../assets/landingpage-icons/up-mobile-arrow.png);
            left: 222px;
            z-index: 2;
            top: 230px;
        }
    }

    .hero--wrapper {
        display: block;
        height: 830px;
        .hero--container {
            width: 100%;
            flex-direction: column-reverse;
            gap: 80px;
            &__left {
                gap: 24px;
                .hero--title {
                    width: 343px;
                    height: 114px;
                    font-style: normal;
                    font-weight: 800;
                    font-size: 36px;
                    line-height: 38px;
                    /* or 106% */

                    letter-spacing: -0.02em;
                }
                .hero--text {
                    width: 343px;
                    height: 40px;

                    font-style: normal;
                    font-weight: 450;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: -0.02em;
                }

                button {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 16px 24px;
                    gap: 12px;

                    width: 343px;
                    height: 50px;

                    /* primary / active */

                    background: #eb2931;
                    border-radius: 4px;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    align-self: stretch;
                    flex-grow: 0;
                }

                .gifters {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0px;
                    gap: 16px;

                    width: 343px;
                    height: 31px;

                    img {
                        width: 84px;
                        height: 24px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 31px;
                        /* identical to box height, or 219% */

                        letter-spacing: -0.02em;
                    }
                }
            }

            &__right {
                width: 375px;
                position: relative;

                .top--icon {
                    right: 10px;

                    width: 58.09px;
                    height: 58.09px;
                    z-index: 2;
                    img {
                        width: 48px;
                        height: 48px;
                    }
                }

                .notification {
                    position: absolute;
                    z-index: 3;
                    width: 156.32px;
                    height: 45.82px;
                    top: 230px;
                    left: 24px;
                    .gift--icon {
                        width: 25px;
                        height: 25px;
                    }

                    p {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 8.86935px;
                        line-height: 11px;
                    }
                }

                .hero--image {
                    width: 305px;
                    height: 350px;
                    position: absolute;
                    left: 35px;
                    top: -17px;
                }
            }
        }
    }
}

@media screen and (min-width: 1290px) and (max-width: 1390px) {
    .hero--wrapper {
        .hero--container {
            gap: 25px;
            &__right {
                width: 568px;
                .notification {
                    top: 480px;
                    left: -120px;
                }
            }
        }
    }

    .how--wrapper {
        width: 100%;
        .how--container {
            padding-top: 40px;
            .how--lists {
                gap: 10px;
                &__item {
                    width: 410px;
                }
            }
        }

        .how--lists::before {
            left: 430px;
            top: 130px;
        }

        .how--lists::after {
            right: 420px;
            bottom: 20px;
        }
    }

    .gifts--wrapper {
        .gifts--container {
            .gift--lists {
                &__item,
                &__item-two {
                    width: 604px;
                }
            }
        }
    }

    .why--container {
        justify-content: center;
        .why--lists {
            width: 96%;
            justify-content: flex-start;
            gap: 20px;
            .why-box {
                width: 400px;
            }
        }
    }

    .testimonial--container {
        &__lists {
            justify-content: center;
            .item {
                width: 354px;
            }
        }
    }

    .banner--container {
        &__inner {
            width: 95%;
        }
    }
}
