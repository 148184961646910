body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: #fbfbfb;
  background: #f6f7f8;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type="radio"] {
  transform: scale(0.65);
  border: 1px solid red;
}

input[type="checkbox"] {
  transform: scale(2);
  border: 1px solid red;
}

a {
  text-decoration: none;
}
