.contact--container {
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;

        color: #000000;
    }
    &__options {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 32px;
        .action {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            height: 62px;

            margin-top: 5px;
            background: #ffffff;
            border: 1px solid rgba(216, 216, 216, 0.7);
            border-radius: 8px;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;

                .icon {
                    width: 45px;
                    height: 45px;
                    border-radius: 100%;
                    background: #f1f5f9;
                    display: grid;
                    place-items: center;
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
                p {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 24px;
                    /* identical to box height, or 170% */

                    color: #000000;

                    /* Inside auto layout */

                    flex: none;
                    order: 1;
                    flex-grow: 0;
                }
            }
        }

        .action:last-child {
            div {
                .icon {
                    background: #f0fdf4;
                }
            }
        }
    }

    &__socials {
        margin-top: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 16px;
        .social {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background: #e2e8f0;
            flex: none;
            order: 0;
            flex-grow: 0;
            z-index: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            a,
            img {
                width: 22px;
                height: 22px;
            }
        }
    }
}
