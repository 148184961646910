.changepassword--container {
    h3 {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 36px;

        color: #000000;
        margin-bottom: 48px;
    }
    .button {
        position: fixed;
        bottom: 20px;
        left: 20px;
        right: 16px;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        button {
            width: 343px;
        }
    }
}

.form__group {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;

    .toggle__password {
        position: absolute;
        top: 50px;
        right: 5%;
        cursor: pointer;
        transition: 0.1s ease-in-out;

        img {
            width: 20px;
            height: 20px;
        }
    }
}

.form__group:nth-child(2) {
    padding-bottom: 4rem;
    overflow-y: scroll;
}

.success--container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &__icon {
        width: 150px;
        height: 150px;
        border-radius: 100%;
        display: grid;
        place-items: center;
        margin-top: 62px;
    }

    &__content {
        h3 {
            width: 290.87px;
            height: 36px;
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;

            text-align: center;

            color: #000000;

            /* Inside auto layout */

            flex: none;
            order: 0;
            align-self: stretch;
            flex-grow: 0;
        }
        p {
            width: 290.87px;
            height: 40px;
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            /* or 143% */

            text-align: center;

            /* gray/2 */

            color: #969696;
        }
    }

    &__button {
        position: fixed;
        bottom: 20px;
        left: 16px;
        right: 16px;
    }
}
