.player--container {
    box-sizing: border-box;
    width: 375px;
    height: 50px;
    background: #ffffff;
    border: 1px solid rgba(216, 216, 216, 0.7);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    .bar__progress {
        width: 199px;
        height: 4px;
        background: #d9d9d9;
        border-radius: 200px;
    }

    .duration {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */

        text-align: center;

        /* gray/2 */

        color: #969696;

        /* Inside auto layout */

        flex: none;
        order: 2;
        flex-grow: 0;
    }
}
