.empty--container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 8px;
        // width: 232px;
        height: 96px;
        .text {
            width: 248px;
            height: 40px;
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #969696;
            flex: none;
            order: 1;
            flex-grow: 0;
        }
    }
}
