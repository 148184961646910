@import "../Login/index.module.scss";
@import "../../styles/base.scss";

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    .forgot--password {
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        form {
            display: flex;
            flex-direction: column;
            width: 375px;
            label {
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 31px;
                /* identical to box height, or 219% */

                color: #000000;
            }
            .button {
                position: fixed;
                bottom: 70px;
                left: 16px;
                right: 16px;
                font-family: "circular_std_book";
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
                display: flex;
                flex-direction: column;
                align-items: center;

                button {
                    width: 375px;
                }
            }
        }

        h3 {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
            text-align: center;
            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 18px;
            // text-align: center;
            width: 369px;
            color: #969696;
        }

        .bottom--link {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 375px;
            position: fixed;
            bottom: 20px;
            p {
                margin-top: 20px;
                font-style: normal;
                font-weight: 450;
                font-size: 16px;
                line-height: 31px;
                color: #000000;
                text-align: center;
            }
        }
    }
}

.sent {
    width: 613px;
    padding: 20px 40px;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #000000;
    }

    p {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        width: 369px;
        color: #969696;
        padding: 10px 0px;
    }

    button {
        font-family: "circular_std_book";
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
    }
}

@media screen and (max-width: 600px) {
    .container {
        .forgot--password {
            width: 100%;
            border-radius: 10px;
            padding: 20px;
            align-items: flex-start;

            h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 30px;
                text-align: left;

                color: #000000;
            }

            p {
                font-style: normal;
                font-weight: 450;
                font-size: 14px;
                line-height: 17px;
                text-align: left;

                color: #969696;
            }

            form {
                width: 100%;
                label {
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    /* identical to box height, or 120% */

                    color: #000000;
                }
                button {
                    font-size: 14px;
                    line-height: 13px;
                }
            }

            .bottom--link {
                text-align: center;

                p {
                    text-align: center;
                }

                a {
                    text-decoration: none;
                }
            }
        }
    }

    .sent {
        width: 334.77px;
        border-radius: 10px;
        padding: 20px;

        h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 30px;
            /* or 125% */

            color: #000000;
        }

        p {
            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 17px;
            text-align: left;
            padding: 10px 0px;
            color: #969696;
        }
    }
}

.verify--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-top: 97px;

    .verify--content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &__image {
            border-radius: 100%;
            display: grid;
            place-items: center;

            img {
                width: 70px;
                height: 70px;
                padding: 20px;
                background: #f6f7f8;
                border-radius: 100%;
            }
        }
        &__title {
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 36px;

            text-align: center;

            color: #000000;
        }

        &__text {
            width: 290.87px;
            height: 36px;

            font-style: normal;
            font-weight: 450;
            font-size: 14px;
            line-height: 18px;
            text-align: center;

            padding-top: 9px;

            color: #969696;
        }
    }

    .button {
        position: fixed;
        bottom: 20px;
        width: 100%;
        left: 16px;
        right: 16px;

        button {
            width: 90vw;
        }
    }
}
